import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { upsert } from '@shared/utils';

interface BaseResource {
  id: number;
}

@Injectable({
  providedIn: 'root',
})
export class ResourceBaseService<T extends BaseResource> {
  http = inject(HttpClient);
  resources = signal<T[]>([]);
  selectedResource = computed(() => {
    return this.resources().find((r) => r.id === this.selectedResourceId());
  });
  selectedResourceId = signal<number | undefined>(undefined);

  selectById(id: number): void {
    this.selectedResourceId.set(id);
  }

  upsertResource = (resource: T, preMappings?: (r: T) => T) => {
    if (preMappings) resource = preMappings(resource);
    this.resources.set(upsert(this.resources(), resource, 'id'));
  };

  deleteResource = (id: number) => {
    this.resources.set(this.resources().filter((r) => r.id !== id));
  };
}
