import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable, tap } from 'rxjs';
import { RESOURCE } from '@core';
import { ICategory } from '@shared/models';
import { ResourceBaseService } from './resource-base.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends ResourceBaseService<ICategory> {
  constructor() {
    super();
    if (!this.resources().length) this.get().subscribe();
  }

  get(): Observable<ICategory[]> {
    return this.http
      .get<ICategory[]>(`${environment.apiUrl}/${RESOURCE.category}`)
      .pipe(tap((categories) => this.resources.set(categories)));
  }
}
