import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { RESOURCE } from '@core';
import { environment } from '@env';
import { Observable, tap } from 'rxjs';
import { EmployeeService } from './employee.service';
import { ICompany, User } from '@shared/models';
import { CompanyService } from './company.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  http = inject(HttpClient);
  employeeService = inject(EmployeeService);
  companyService = inject(CompanyService);
  userService = inject(UserService);

  updateCompanyLogotype(file: File): Observable<ICompany> {
    const { formData, headers } = this.createFileFormDataWithHeaders(file);
    return this.http
      .put<ICompany>(
        `${environment.apiUrl}/${RESOURCE.company}/logotype`,
        formData,
        { headers }
      )
      .pipe(tap((company) => this.companyService.upsertResource(company)));
  }

  deleteCompanyLogotype(): Observable<ICompany> {
    return this.http
      .delete<ICompany>(`${environment.apiUrl}/${RESOURCE.company}/logotype`)
      .pipe(tap((company) => this.companyService.upsertResource(company)));
  }

  updateAvatar(file: File, employeeId?: number): Observable<User> {
    return employeeId
      ? this.uploadEmployeeAvatar(file, employeeId)
      : this.uploadOwnAvatar(file);
  }

  deleteAvatar(employeeId?: number): Observable<User> {
    return employeeId
      ? this.deleteEmployeeAvatar(employeeId)
      : this.deleteOwnAvatar();
  }

  deleteOwnAvatar(): Observable<User> {
    return this.userService.deleteAvatar();
  }

  deleteEmployeeAvatar(employeeId: number): Observable<User> {
    return this.http
      .delete<User>(
        `${environment.apiUrl}/${RESOURCE.employee}/${employeeId}/avatar`
      )
      .pipe(
        tap((user) => {
          this.employeeService.upsertResource(user);
          if (this.userService.user()?.id === user.id) {
            this.userService.setUser(user);
          }
        })
      );
  }

  uploadOwnAvatar(file: File): Observable<User> {
    const { formData, headers } = this.createFileFormDataWithHeaders(file);
    return this.http
      .put<User>(`${environment.apiUrl}/${RESOURCE.user}/avatar`, formData, {
        headers,
      })
      .pipe(tap((user) => this.userService.setUser(user)));
  }

  uploadEmployeeAvatar(file: File, employeeId: number): Observable<User> {
    const { formData, headers } = this.createFileFormDataWithHeaders(file);
    return this.http
      .put<User>(
        `${environment.apiUrl}/${RESOURCE.employee}/${employeeId}/avatar`,
        formData,
        { headers }
      )
      .pipe(
        tap((user) => {
          this.employeeService.upsertResource(user);
          if (this.userService.user()?.id === user.id) {
            this.userService.setUser(user);
          }
        })
      );
  }

  createFileFormDataWithHeaders(file: File): {
    formData: FormData;
    headers: HttpHeaders;
  } {
    const formData = new FormData();
    formData.append('image', file);
    const headers = new HttpHeaders();
    headers.append('Content-Type', file.type);
    return { formData, headers };
  }
}
