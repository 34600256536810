import { Injectable, inject, signal } from '@angular/core';
import { LANG_LS_NAME } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { Language } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  language = signal<Language>(Language.Swedish);
  translate = inject(TranslateService);

  initalizeDefaultLanguage() {
    const lang =
      localStorage.getItem(LANG_LS_NAME) ||
      this.translate.getBrowserLang() ||
      'sv';
    this.translate.use(lang);
    this.language.set(lang as Language);
    return this.translate.currentLoader.getTranslation(lang).pipe(take(1));
  }
}
