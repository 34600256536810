import { Injectable, computed, inject, signal } from '@angular/core';
import { AuthService, RESOURCE, URLS } from '@core';
import { environment } from '@env';
import {
  Company,
  ICompany,
  ICompanySummary,
  ICompanySummaryResult,
  ICreateCompany,
  LoginResponseDto,
} from '@shared/models';
import { ResourceBaseService } from './resource-base.service';
import { BehaviorSubject, Observable, mergeMap, tap } from 'rxjs';
import { UserService } from './user.service';
import { Router } from '@angular/router';
export const LAST_STORED_COMPANY_LS_NAME = '__lastStoredCompany';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends ResourceBaseService<ICompany> {
  userService = inject(UserService);
  router = inject(Router);
  authService = inject(AuthService);
  stepsResults = signal<ICompanySummaryResult>({
    isLoading: true,
    isValid: false,
    hasEmployees: false,
    hasServices: false,
    hasValidCompanyDetails: false,
  });
  hasCompanies = signal(true);
  companySwitchSignal = signal<number>(-1);
  private selectedCompanyBS$ = new BehaviorSubject<ICompany | undefined>(
    undefined
  );
  isCompanyUser = computed(() => {
    return !!this.selectedCompany();
  });
  selectedCompany$ = this.selectedCompanyBS$.asObservable();

  selectedCompany = computed(() => {
    return this.resources().find((c) => {
      return c.id === this.selectedResourceId();
    });
  });

  get lastStoredCompanyId(): number {
    return Number(localStorage.getItem(LAST_STORED_COMPANY_LS_NAME) || -1);
  }

  /** Functions */
  selectCompany(company: ICompany) {
    if (!company) return;
    localStorage.setItem(LAST_STORED_COMPANY_LS_NAME, company.id.toString());
    this.selectedCompanyBS$.next(company);
    this.selectedResourceId.set(company.id);
  }

  /** APIS */
  doesCompanyExistWithOrgNumber(orgNumber: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrl}/${RESOURCE.company}/orgNumber/exists`,
      { organizationNumber: orgNumber }
    );
  }

  getOne(): Observable<ICompany> {
    return this.http
      .get<ICompany>(`${environment.apiUrl}/${RESOURCE.company}`)
      .pipe(tap((company) => this.selectCompany(company)));
  }

  switchCompany(companyId: number): Observable<ICompany> {
    return this.http
      .post<LoginResponseDto>(
        `${environment.apiUrl}/${RESOURCE.auth}/company-selection`,
        { companyId }
      )
      .pipe(
        mergeMap((loginRes: LoginResponseDto) => {
          this.userService.setUser(loginRes);
          this.authService.token = loginRes.accessToken;

          return this.getOne().pipe(
            tap((company) => {
              this.selectCompany(company);
              this.router.navigate([URLS.onboarding]);
              this.companySwitchSignal.set(Math.random());
            })
          );
        })
      );
  }

  create(company: Partial<ICompany>): Observable<ICreateCompany> {
    return this.http
      .post<ICreateCompany>(
        `${environment.apiUrl}/${RESOURCE.company}`,
        company
      )
      .pipe(
        tap(({ company, loginResponse }) => {
          this.userService.setUser(loginResponse);
          this.authService.token = loginResponse.accessToken;
          this.selectCompany(company);
          this.upsertResource(company);
          this._updateStepsResults();
          this.router.navigate([URLS.onboarding]);
        })
      );
  }

  update(company: Partial<ICompany>): Observable<ICompany> {
    return this.http
      .patch<ICompany>(`${environment.apiUrl}/${RESOURCE.company}`, company)
      .pipe(
        tap((company) => {
          this.selectCompany(company);
          this.upsertResource(company);
          this._updateStepsResults();
        })
      );
  }

  getAll(): Observable<ICompany[]> {
    return this.http
      .get<ICompany[]>(`${environment.apiUrl}/${RESOURCE.company}/all`)
      .pipe(
        tap((companies) => {
          const nextCompany =
            companies.find((c) => c.id === this.lastStoredCompanyId) ||
            companies[0];
          this.resources.set(companies);
          this.selectCompany(nextCompany);
          this.hasCompanies.set(companies.length > 0);
          if (
            this.router.url.includes(URLS.companySelection) &&
            companies.length === 1
          ) {
            this.router.navigate([URLS.root]);
          }
        })
      );
  }

  getCompanySummary(): Observable<ICompanySummary> {
    return this.http
      .get<ICompanySummary>(`${environment.apiUrl}/${RESOURCE.company}/summary`)
      .pipe(
        tap((summary) => {
          const company = new Company();
          const companyForm = company.createCompanyDetailForm(summary.company);

          const isValid =
            companyForm.valid &&
            summary.employeeCount > 0 &&
            summary.serviceCount > 0;

          this.stepsResults.set({
            isLoading: false,
            isValid,
            hasEmployees: summary.employeeCount > 0,
            hasServices: summary.serviceCount > 0,
            hasValidCompanyDetails: companyForm.valid,
          });
        })
      );
  }

  private _updateStepsResults() {
    // this.stepsResults.update((steps) => {
    //   steps.hasValidCompanyDetails = true;
    //   steps.isValid =
    //     steps.hasServices && steps.hasEmployees && steps.hasValidCompanyDetails;
    // });
  }
}
