<aside [class.is-mobile-menu-open]="showMenu()">
  <ul class="mt-5 block">
    @if(isCompanyUser()){
    <li>
      <p class="bf-sidebar__title">Mitt företag</p>

      <ul class="mb-5">
        <li>
          <a
            [routerLink]="['/company']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact: true
            }"
            class="bf-sidebar__link"
          >
            <bf-icon [size]="24">store</bf-icon>
            <div>Företagsprofil</div>
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/users']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact: true
            }"
            class="bf-sidebar__link"
          >
            <bf-icon [size]="24">people</bf-icon>
            <div>Användare</div>
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/company/invoices']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact: true
            }"
            class="bf-sidebar__link"
          >
            <bf-icon [size]="24">receipt</bf-icon>
            <div>Fakturor</div>
          </a>
        </li>
      </ul>
    </li>
    }

    <li>
      <p class="bf-sidebar__title">Mina objekt</p>

      <ul class="mb-5">
        <li>
          <a
            [routerLink]="['/objects']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact: true
            }"
            class="bf-sidebar__link flex items-center justify-between"
          >
            <div class="flex items-center gap-3">
              <bf-icon [size]="24">house</bf-icon>
              <div>Bostäder</div>
            </div>

            <a
              class="d-inline-flex"
              tabindex="-1"
              routerLink="/objects"
              (click)="$event.stopPropagation()"
              [queryParams]="{ add: true }"
            >
              <bf-icon-button
                id="addPropertySidebarButton"
                aria-label="Lägg till bostad"
                size="sm"
                icon="add"
                class="sm:-my-2 -my-4 sm:mr-0 -mr-1"
              />
            </a>
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/objects/applicants']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact: true
            }"
            class="bf-sidebar__link"
          >
            <bf-icon [size]="24">category</bf-icon>
            <div>Intressenter</div>
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/objects/biddings']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact: true
            }"
            class="bf-sidebar__link"
          >
            <bf-icon [size]="24">gavel</bf-icon>
            <div>Budgivningar</div>
          </a>
        </li>
      </ul>
    </li>

    <li>
      <p class="bf-sidebar__title">Min profil</p>

      <ul class="mb-5">
        <li>
          <a
            [routerLink]="['/account/profile']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact: true
            }"
            class="bf-sidebar__link"
          >
            <bf-icon [size]="24">person</bf-icon>
            <div>Profil</div>
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/account/settings']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact: true
            }"
            class="bf-sidebar__link"
          >
            <bf-icon [size]="24">settings</bf-icon>
            <div>Inställningar</div>
          </a>
        </li>
        <li>
          <a href="{{ mainUrl }}/kontakt" class="bf-sidebar__link">
            <bf-icon [size]="24">feedback</bf-icon>
            <div>Feedback</div>
          </a>
        </li>
        <li>
          <a href="#" (click)="signOut()" class="bf-sidebar__link">
            <bf-icon [size]="24">logout</bf-icon>
            <div>Logga ut</div>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</aside>

@if(showMenu()){
<div (click)="closeMenu()" class="mobile-overlay"></div>
}
