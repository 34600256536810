import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { RESOURCE } from '@core';
import { environment } from '@env';
import { Observable, map, tap } from 'rxjs';
import { IPrivilege } from '../models/privilege.model';
import { UserRoles } from '../enums';
import { CompanyService } from './company.service';
import { UserService } from './user.service';
import { User } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class PrivilegeService {
  userService = inject(UserService);
  companyService = inject(CompanyService);
  privilege = signal<IPrivilege | undefined>(undefined);

  isAuthorizedToEdit = computed(() => {
    return (
      this.isAdminOrOwner() ||
      this.companyService.selectedCompany()?.adminUserId ===
        this.userService.user()?.id
    );
  });

  isCurrentUser(user: User) {
    return this.userService.isCurrentUser(user);
  }

  constructor(private http: HttpClient) {}

  get(): Observable<IPrivilege> {
    return this.http
      .get<IPrivilege>(
        `${environment.apiUrl}/user-company/${RESOURCE.privilege}`
      )
      .pipe(tap((privilege) => this.privilege.set(privilege)));
  }

  isAdminOrOwner(): boolean {
    if (!this.privilege()) return false;
    return this.privilege()?.role === UserRoles.Admin;
  }

  isAdminOrOwner$(): boolean | Observable<boolean> {
    if (this.isAdminOrOwner()) return true;
    return this.get().pipe(
      map((p) => {
        return p.role === UserRoles.Admin;
      })
    );
  }
}
