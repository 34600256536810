import { Injectable } from '@angular/core';
import { environment } from '@env';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const AUTH_TOKEN = 'token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get token(): string {
    return Cookies.get(AUTH_TOKEN) || '';
  }

  set token(token: string) {
    token
      ? Cookies.set(AUTH_TOKEN, token, {
          domain: environment.production ? '.bofynd.se' : 'localhost',
          path: '/',
          secure: true,
          sameSite: 'None',
        })
      : Cookies.remove(AUTH_TOKEN, {
          domain: environment.production ? '.bofynd.se' : 'localhost',
        });
  }

  isTokenValid(): boolean {
    try {
      const decodedToken = jwtDecode(this.token);
      if (!decodedToken || !decodedToken.exp) {
        this.token = '';
        return false;
      }
      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp > currentTime;
    } catch (error) {
      this.token = '';
      return false;
    }
  }
}
