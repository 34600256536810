export const URLS = {
  root: '',
  account: 'account',
  login: 'account/login',
  companySelection: 'account/selection',
  register: 'account/register',
  profile: 'profile',
  settings: 'settings',
  company: 'company',
  services: 'services',
  locations: 'locations',
  bookings: 'bookings',
  users: 'users',
  onboarding: 'onboarding',
  resetPassword: 'reset',
  inivationAccept: 'invitation/accept',
  inivationAcceptWithReset: 'invitation/accept/reset',
  sales: 'objects',
  invoices: 'company/invoices',
};
