import { Injectable, inject } from '@angular/core';
import { RESOURCE } from '@core';
import { environment } from '@env';
import { ICompanyImages } from '@shared/models';
import { Observable, tap } from 'rxjs';
import { ResourceBaseService } from './resource-base.service';
import { ImageService } from './image.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyImagesService extends ResourceBaseService<ICompanyImages> {
  imageService = inject(ImageService);

  getCompanyImages(): Observable<ICompanyImages[]> {
    return this.http
      .get<ICompanyImages[]>(`${environment.apiUrl}/${RESOURCE.companyImage}`)
      .pipe(tap((images) => this.resources.set(images)));
  }

  removeCompanyImage(companyImageId: number): Observable<boolean> {
    return this.http
      .delete<boolean>(
        `${environment.apiUrl}/${RESOURCE.companyImage}/${companyImageId}`
      )
      .pipe(tap(() => this.deleteResource(companyImageId)));
  }

  reorderCompanyImages(
    companyImages: ICompanyImages[]
  ): Observable<ICompanyImages[]> {
    return this.http.put<ICompanyImages[]>(
      `${environment.apiUrl}/${RESOURCE.companyImage}`,
      companyImages
    );
  }

  addCompanyImage(file: File): Observable<ICompanyImages> {
    const { formData, headers } =
      this.imageService.createFileFormDataWithHeaders(file);

    return this.http
      .post<ICompanyImages>(
        `${environment.apiUrl}/${RESOURCE.companyImage}`,
        formData,
        { headers }
      )
      .pipe(tap((image) => this.upsertResource(image)));
  }
}
