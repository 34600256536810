import { Injectable, inject } from '@angular/core';
import { ALL_USER_ROLES, RESOURCE } from '@core';
import { environment } from '@env';
import { User } from '@shared/models';
import { Observable, tap } from 'rxjs';
import { ResourceBaseService } from './resource-base.service';
import { UserService } from './user.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends ResourceBaseService<User> {
  userService = inject(UserService);
  companyService = inject(CompanyService);

  get(): Observable<User[]> {
    return this.http
      .get<User[]>(`${environment.apiUrl}/${RESOURCE.companyEmployee}`)
      .pipe(
        tap((employees) => {
          this.resources.set(employees);
          if (employees.length === 1) this.selectById(employees[0].id);
        })
      );
  }

  create(employee: User): Observable<User> {
    return this.http
      .post<User>(`${environment.apiUrl}/${RESOURCE.companyEmployee}`, {
        ...employee,
      })
      .pipe(
        tap((emp) =>
          this.upsertResource(emp, this._mapUserToEmployee.bind(this))
        )
      );
  }

  bulkCreate(employees: User[]): Observable<User[]> {
    return this.http.post<User[]>(
      `${environment.apiUrl}/${RESOURCE.companyEmployee}/bulk`,
      employees
    );
  }

  update(employee: User): Observable<User> {
    return this.http
      .put<User>(
        `${environment.apiUrl}/${RESOURCE.companyEmployee}/${employee.id}`,
        employee
      )
      .pipe(
        tap((emp) => {
          this.upsertResource(emp, this._mapUserToEmployee.bind(this));
          if (this.userService.user()?.id === emp.id) {
            this.userService.setUser(emp);
          }
        })
      );
  }

  delete(employeeId: number): Observable<boolean> {
    return this.http
      .delete<boolean>(
        `${environment.apiUrl}/${RESOURCE.companyEmployee}/${employeeId}`
      )
      .pipe(tap(() => this.deleteResource(employeeId)));
  }

  private _mapAllUsersToEmployees = (users: User[]): User[] => {
    users.forEach((user) => (user = this._mapUserToEmployee(user)));
    return users;
  };

  private _mapUserToEmployee(user: User): User {
    // user.name = user.displayName;
    user.rolesLabel =
      ALL_USER_ROLES.find((r) => r.value === user.roles)?.label || '';

    return user;
  }
}
