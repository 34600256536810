import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserRoles } from '../enums';
import { LoginResponseDto, User } from './user.model';
import {
  ICategory,
  ICompanySettings,
  IOpeningHoursForm,
  OpeningHours,
} from '@shared/models';
import { CompanyService, UserService } from '@shared/services';
import { CompanyValidators, UserValidators } from '@shared/ui/form/validators';

export interface EmployeeForm {
  id: FormControl<number | undefined>;
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string | null>;
  phoneNumber: FormControl<string | null>;
  roles?: FormControl<UserRoles>;
  avatarUrl?: FormControl<string | null>;
}

export interface EmployeeFormSlim {
  id: FormControl<number | undefined>;
  displayName: FormControl<string | null>;
  email: FormControl<string | null>;
  phoneNumber: FormControl<string | null>;
  roles: FormControl<UserRoles>;
  avatarUrl: FormControl<string | null>;
}

export interface ICompany extends IAddress {
  id: number;
  name: string;
  organizationNumber: string;
  roles: UserRoles;
  description: string;
  website: string;
  phoneNumber: string;
  slug: string;
  logotypeUrl: string;
  email: string;
  adminUserId: number;
  published: boolean;
  categories: ICategory[];
  avatar: string;
}

export interface IAddress {
  address: string;
  zipCode: string;
  city: string;
  vicinity: string;
  county: string;
  country: string;
  lng: number;
  lat: number;
}

interface CompanyForm {
  id: FormControl<number | undefined>;
  name: FormControl<string | undefined>;
  description: FormControl<string | undefined>;
  phoneNumber: FormControl<string | undefined>;
  email: FormControl<string | undefined>;
  organizationNumber: FormControl<string | undefined>;
  website: FormControl<string | undefined>;
  address: FormControl<string | undefined>;
  city: FormControl<string | undefined>;
  county: FormControl<string | undefined>;
  vicinity: FormControl<string | undefined>;
  zipCode: FormControl<string | undefined>;
  country: FormControl<string | undefined>;
  lng: FormControl<number | undefined>;
  lat: FormControl<number | undefined>;
  selectedCategoryIds: FormControl<number[] | undefined>;
}
export interface ICompanyImages {
  id: number;
  companyId: number;
  url: string;
  sequence: number;
}

export interface ICreateCompany {
  company: ICompany;
  loginResponse: LoginResponseDto;
}

export interface ICompanySummary {
  company: ICompany;
  openingHours: OpeningHours;
  employeeCount: number;
  serviceCount: number;
}

export class Company {
  fb = new FormBuilder();

  createCompanySlimForm(companyService: CompanyService): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      organizationNumber: [
        '',
        [Validators.required, CompanyValidators.orgNumberValidator()],
        [CompanyValidators.orgNumberExists(companyService)],
      ],
    });
  }

  createCompanyDetailForm(company?: ICompany): FormGroup<CompanyForm> {
    return this.fb.nonNullable.group<CompanyForm>({
      id: this.fb.nonNullable.control(company?.id, {
        validators: Validators.required,
      }),
      name: this.fb.nonNullable.control(company?.name, {
        validators: Validators.required,
      }),
      description: this.fb.nonNullable.control(company?.description),
      phoneNumber: this.fb.nonNullable.control(company?.phoneNumber, {
        validators: Validators.required,
      }),
      email: this.fb.nonNullable.control(company?.email, {
        validators: Validators.required,
      }),
      organizationNumber: this.fb.nonNullable.control(
        company?.organizationNumber,
        { validators: Validators.required }
      ),
      website: this.fb.nonNullable.control(company?.website),
      address: this.fb.nonNullable.control(company?.address, {
        validators: Validators.required,
      }),
      city: this.fb.nonNullable.control(company?.city, {
        validators: Validators.required,
      }),
      county: this.fb.nonNullable.control(company?.county, {
        validators: Validators.required,
      }),
      vicinity: this.fb.nonNullable.control(company?.vicinity, {
        validators: Validators.required,
      }),
      zipCode: this.fb.nonNullable.control(company?.zipCode, {
        validators: Validators.required,
      }),
      country: this.fb.nonNullable.control(company?.country, {
        validators: Validators.required,
      }),
      lng: this.fb.nonNullable.control(company?.lng, {
        validators: Validators.required,
      }),
      lat: this.fb.nonNullable.control(company?.lat, {
        validators: Validators.required,
      }),
      selectedCategoryIds: this.fb.nonNullable.control(
        company?.categories?.map((c) => c.id) || []
      ),
    });
  }

  createOpeningHoursForm(
    openingHours?: OpeningHours
  ): FormGroup<IOpeningHoursForm> {
    return this.fb.group<IOpeningHoursForm>({
      mondayStartTime: this.fb.control(
        openingHours?.mondayStartTime || '',
        Validators.required
      ),
      mondayEndTime: this.fb.control(
        openingHours?.mondayEndTime || '',
        Validators.required
      ),
      mondayClosed: this.fb.control(openingHours?.mondayClosed || false),
      tuesdayStartTime: this.fb.control(
        openingHours?.tuesdayStartTime || '',
        Validators.required
      ),
      tuesdayEndTime: this.fb.control(
        openingHours?.tuesdayEndTime || '',
        Validators.required
      ),
      tuesdayClosed: this.fb.control(openingHours?.tuesdayClosed || false),
      wednesdayStartTime: this.fb.control(
        openingHours?.wednesdayStartTime || '',
        Validators.required
      ),
      wednesdayEndTime: this.fb.control(
        openingHours?.wednesdayEndTime || '',
        Validators.required
      ),
      wednesdayClosed: this.fb.control(openingHours?.wednesdayClosed || false),
      thursdayStartTime: this.fb.control(
        openingHours?.thursdayStartTime || '',
        Validators.required
      ),
      thursdayEndTime: this.fb.control(
        openingHours?.thursdayEndTime || '',
        Validators.required
      ),
      thursdayClosed: this.fb.control(openingHours?.thursdayClosed || false),
      fridayStartTime: this.fb.control(
        openingHours?.fridayStartTime || '',
        Validators.required
      ),
      fridayEndTime: this.fb.control(
        openingHours?.fridayEndTime || '',
        Validators.required
      ),
      fridayClosed: this.fb.control(openingHours?.fridayClosed || false),

      saturdayStartTime: this.fb.control(
        openingHours?.saturdayStartTime || '',
        Validators.required
      ),
      saturdayEndTime: this.fb.control(
        openingHours?.saturdayEndTime || '',
        Validators.required
      ),
      saturdayClosed: this.fb.control(openingHours?.saturdayClosed || false),

      sundayStartTime: this.fb.control(
        openingHours?.sundayStartTime || '',
        Validators.required
      ),
      sundayEndTime: this.fb.control(
        openingHours?.sundayEndTime || '',
        Validators.required
      ),
      sundayClosed: this.fb.control(openingHours?.sundayClosed || false),
    });
  }

  // createEmployeeForm(
  //   userService: UserService,
  //   employee?: User
  // ): FormGroup<EmployeeForm> {
  //   const form = this.fb.group<EmployeeForm>({
  //     id: this.fb.control<number | undefined>(employee?.id, {
  //       nonNullable: true,
  //     }),
  //     displayName: this.fb.control(employee?.displayName || '', {
  //       validators: Validators.required,
  //       nonNullable: true,
  //     }),
  //     roles: this.fb.control(employee ? employee.roles : UserRoles.Employee, {
  //       validators: Validators.required,
  //       nonNullable: true,
  //     }),
  //     email: this.fb.control(employee?.email || '', [
  //       Validators.required,
  //       Validators.email,
  //     ]),
  //     phoneNumber: this.fb.control(employee?.phoneNumber || ''),
  //     avatarUrl: this.fb.control(employee?.avatarUrl || ''),
  //     userWorkingSchedule: this.createOpeningHoursForm(
  //       employee?.userWorkingSchedule
  //     ),
  //   });

  //   return form;
  // }

  // createUserForm()

  createEmployeeSlimForm(
    userService: UserService,
    employee?: User
  ): FormGroup<EmployeeFormSlim> {
    const form = this.fb.group<EmployeeFormSlim>({
      id: this.fb.control<number | undefined>(employee?.id, {
        nonNullable: true,
      }),
      displayName: this.fb.control('', {
        validators: Validators.required,
        nonNullable: true,
      }),
      roles: this.fb.control(employee ? employee.roles : UserRoles.Member, {
        validators: Validators.required,
        nonNullable: true,
      }),
      email: this.fb.control(
        employee?.email || '',
        [Validators.required, Validators.email],
        [UserValidators.emailExists(userService, employee?.email)]
      ),
      phoneNumber: this.fb.control(employee?.phoneNumber || ''),
      avatarUrl: this.fb.control(employee?.avatar || ''),
    });

    return form;
  }

  createCompanySettingsForm(
    settings: ICompanySettings = new CompanySettings()
  ) {
    return this.fb.nonNullable.group({
      isPublished: this.fb.nonNullable.control(settings.isPublished),
      restrictBookingsToMembers: this.fb.nonNullable.control(
        settings.restrictBookingsToMembers
      ),
      autoAcceptBookings: this.fb.nonNullable.control(
        settings.autoAcceptBookings
      ),
    });
  }
}

export class CompanySettings {
  id = -1;
  isPublished = true;
  restrictBookingsToMembers = false;
  autoAcceptBookings = true;
}

export interface ICompanySummaryResult {
  isLoading: boolean;
  isValid: boolean;
  hasEmployees: boolean;
  hasServices: boolean;
  hasValidCompanyDetails: boolean;
}
