import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IBidding } from './bidding.model';

export type PublishType = 'published' | 'upcoming' | 'draft';
export type ListingType = 'buy' | 'rent';
export enum PropertyType {
  House = 'house',
  Apartment = 'apartment',
  Office = 'office',
  Land = 'land',
  TreatmentRoom = 'treatmentRoom',
  Commercial = 'commercial',
  Industrial = 'industrial',
  Retail = 'retail',
  Warehouse = 'warehouse',
  Studio = 'studio',
  SportsHall = 'sportsHall',
  Other = 'other',
}

export interface ICreateOrUpdatePropertyDto {
  id?: number;
  listingType: ListingType;
  publishType: PublishType;
  type: PropertyType;
  object: {
    price: number;
    size: number;
    rooms: number;
    beds: number;
    baths: number;
  };
  address: {
    address: string;
    zipCode: string;
    city: string;
    county: string;
    country: string;
    sublocality: string;
    lat: string;
    lng: string;
  };
  images: string[];
}

export interface ViewingTime {
  id: number;
  day: string;
  fromTime: string;
  toTime: string;
}
export interface Broker {
  id: number;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  phoneNumber: string;
  emailVerified: boolean;
}

export interface Listing {
  id: number;
  title: string | null;
  slug: string;
  address: string;
  zipCode: string;
  city: string;
  county: string;
  country: string;
  municipality: string;
  sublocality: string;
  lat: number;
  lng: number;
  ingress: string | null;
  description: string | null;
  activeBidding?: boolean;
  thumbnailId: number | null;
  views: number;
  size: number;
  plotSize: number;
  rooms: number;
  beds: number;
  baths: number;
  price: number;
  listingType: ListingType;
  type: PropertyType;
  publishType: PublishType;
  createdAt: string;
  updatedAt: string;
  listingPhotos: string[] | IPropertyImage[];
  brokers: Broker[];
  viewingTimes: ViewingTime[];

  biddings?: IBidding[];
  operatingCost: number;

  details: {
    brokerUrl: string;
    energyClass: string;
    constructionYear: number;
    sellersWord: string;
    buildingType: string;
    foundation: string;
    windowType: string;
    roof: string;
    ventilation: string;
    waterAndSewage: string;
    heating: string;
    broadband: string;
    facade: string;
    others: string;
  };

  tags: {
    central: boolean;
    nearTheBeach: boolean;
    newlyBuilt: boolean;
    balcony: boolean;
    terrace: boolean;
    elevator: boolean;
    parking: boolean;
    garage: boolean;
    garden: boolean;
    fireplace: boolean;
    furnished: boolean;
    sauna: boolean;
    pool: boolean;
  };

  applications: IApplication[];
}

export interface IApplication {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  property: Listing;
  message: string;
}

export interface IPropertyImage {
  id?: number;
  imageUrl: string;
  order: number;
}

export type IPropertyImageForm = FormArray<
  FormGroup<{
    imageUrl: FormControl<string | null>;
    order: FormControl<number | null>;
    id: FormControl<number | null | undefined>;
  }>
>;
