import { Injectable } from '@angular/core';
import { ResourceBaseService } from './resource-base.service';
import { IMunicipality } from '@shared/models';
import { Observable, tap } from 'rxjs';
import { environment } from '@env';
import { RESOURCE } from '@core';

@Injectable({
  providedIn: 'root',
})
export class MunicipalityService extends ResourceBaseService<IMunicipality> {
  constructor() {
    super();
    if (!this.resources().length) this.get().subscribe();
  }

  get(): Observable<IMunicipality[]> {
    return this.http
      .get<IMunicipality[]>(
        `${environment.apiUrl}/${RESOURCE.municipality}/slim`
      )
      .pipe(tap((municipalities) => this.resources.set(municipalities)));
  }
}
