<nav class="nav">
  <ul class="flex justify-between gap-4 px-4 py-4 w-full">
    <li class="flex items-center">
      <button
        [class.burgerOpen]="showMenu()"
        class="hide-on-tablet-up burger"
        (click)="toggleMenuClick()"
        aria-label="Öppna eller stäng meny"
      >
        <span class="burgerLine"></span>
        <span class="burgerLine"></span>
        <span class="burgerLine"></span>
      </button>

      <a href="{{ mainUrl }}" class="logotype shrink-0">
        <img src="./logotype.svg" alt="Bofynd Logotyp" />
      </a>
    </li>
    <li class="list-items flex gap-8 items-center">
      @if(loadUser.isLoading || !loadUser.data()) {
      <bf-skeleton-loader height="20px" width="100px" />
      } @else {
      <bf-menu
        icon="keyboard_arrow_down"
        [label]="loadUser.data()!.firstName"
        [avatar]="loadUser?.data()?.userCompany?.avatarUrl"
      >
        @if(loadCompanies.data() && loadCompanies.data()!.length > 0){
        <div class="dropdown-company">
          <strong class="small-text d-block mb-1">{{
            "company" | translate
          }}</strong>
          @for(company of loadCompanies.data(); track company.id) {
          <div>
            <span>{{ company.name }}</span>
          </div>
          }
        </div>
        }
        <a class="flex items-center gap-1 dropdown-item" href="{{ mainUrl }}">
          <bf-icon>home</bf-icon>
          Till huvudsidan
        </a>
        <a
          class="flex items-center gap-1 dropdown-item"
          [routerLink]="['/account/profile']"
        >
          <bf-icon>person</bf-icon>
          {{ "profile" | translate }}
        </a>

        <a class="flex items-center gap-1 dropdown-item" (click)="signOut()">
          <bf-icon>logout</bf-icon>
          {{ "log_out" | translate }}
        </a>
      </bf-menu>
      }
    </li>
  </ul>
</nav>
