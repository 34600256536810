import { Injectable, inject } from '@angular/core';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root',
})
export class CompanySelectionService {
  private readonly _companyService = inject(CompanyService);

  get companies() {
    return this._companyService.resources;
  }
  get hasCompanies() {
    return this._companyService.hasCompanies;
  }

  selectCompany(companyId: number) {
    return this._companyService.switchCompany(companyId);
  }
}
