export const RESOURCE = {
  auth: 'auth',
  user: 'user',
  bidding: 'bidding',
  company: 'company',
  companyEmployee: 'company-employee',
  companyImage: 'CompanyImage',
  companyLocation: 'Location',
  openingHours: 'OpeningHours',
  services: 'Services',
  employee: 'company/employee',
  currency: 'Currency',
  location: 'Location',
  userLocationEmployees: 'employees',
  privilege: 'privilege',
  booking: 'Booking',
  category: 'Category',
  companySettings: 'CompanySettings',
  municipality: 'municipality',
};
