import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IconComponent } from '../../../shared/ui/icon/icon.component';
import { URLS } from '../../constants';
import { CompanyService, UserService } from '@shared/services';
import { AuthService } from '@core';
import { ButtonModule } from '@shared/ui';
import { environment } from '@env';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule, IconComponent, ButtonModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  @Input({ required: true }) showMenu = signal(false);
  @Output() menuClosed = new EventEmitter<void>();
  private readonly companyService = inject(CompanyService);
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);
  readonly mainUrl = environment.mainUrl;

  get isCompanyUser() {
    return this.companyService.isCompanyUser;
  }

  items = [
    {
      title: 'Översikt',
      icon: 'dashboard',
      href: '/',
    },
    {
      title: 'Företag',
      icon: 'store',
      href: '/company',
      hidden: this.selectedCompany,
    },
    {
      title: 'Mäklare',
      icon: 'people',
      href: URLS.users,
      hidden: this.selectedCompany,
    },
    {
      title: 'Objekt',
      icon: 'house',
      href: URLS.sales,
    },
    // {
    //   title: 'Sparat',
    //   icon: 'saved',
    //   href: URLS.settings,
    // },
  ];

  get selectedCompany() {
    return this.companyService.selectedCompany;
  }

  closeMenu() {
    this.showMenu.set(false);
  }

  signOut() {
    this.authService.token = '';
    this.userService.setUser(undefined);
    this.router.navigate([URLS.login]);
  }
}
