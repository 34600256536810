import { UserRoles } from '../../shared/enums';

export const APP_NAME = 'Tingad';

export const LANG_LS_NAME = '__lang';

// TODO: This should come form the BE later
export const CURRENCY = [
  {
    label: 'SEK (Svenska kronor)',
    value: 'SEK',
  },
  {
    label: 'USD (American dollars)',
    value: 'USD',
  },
];

export const ALL_USER_ROLES = [
  {
    label: 'Member',
    value: UserRoles.Member,
  },
  {
    label: 'Admin',
    value: UserRoles.Admin,
  },
];
