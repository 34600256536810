import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { RESOURCE } from '@core';
import { environment } from '@env';
import {
  AcceptInivationAndResetPasswordDto,
  IChangePassword,
  LoginDto,
  LoginResponseDto,
  RegisterDto,
  User,
} from '@shared/models';
import { BehaviorSubject, Observable, tap } from 'rxjs';

export const USER_LOCAL_STORAGE_KEY = '__user';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private usersBS$ = new BehaviorSubject<User[]>([]);
  users$ = this.usersBS$.asObservable();
  user = signal<Partial<User> | undefined>(undefined);

  isCurrentUser(user: User) {
    return this.user()?.id === user.id;
  }

  constructor(private http: HttpClient) {
    const userLS = localStorage.getItem(USER_LOCAL_STORAGE_KEY) || '';
    const user = userLS ? JSON.parse(userLS) : undefined;
    this.user.set(user);
  }

  setUser(user: Partial<User> | undefined) {
    user
      ? localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user || ''))
      : localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    this.user.set(user);
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrl}/${RESOURCE.user}/reset`,
      { email, requestUrl: window.location.origin }
    );
  }

  changeResettedPassword(form: {
    email: string;
    token: string;
    password: string;
  }): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>(
      `${environment.apiUrl}/${RESOURCE.user}/reset`,
      form
    );
  }

  changePassword(userForm: IChangePassword): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/${RESOURCE.user}/update-password`,
      userForm
    );
  }

  getOne(): Observable<User> {
    return this.http
      .get<User>(`${environment.apiUrl}/${RESOURCE.user}`)
      .pipe(tap((user) => this.setUser(user)));
  }

  update(user: Partial<User>): Observable<User> {
    return this.http
      .patch<User>(`${environment.apiUrl}/${RESOURCE.user}`, user)
      .pipe(tap((user) => this.setUser(user)));
  }

  /** APIS */
  login(loginDto: LoginDto): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>(
      `${environment.apiUrl}/${RESOURCE.auth}/login`,
      loginDto
    );
  }

  loginWithCompany(companyId: number): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>(
      `${environment.apiUrl}/${RESOURCE.auth}/profile-selection`,
      { companyId }
    );
  }

  register(registerDto: RegisterDto): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>(
      `${environment.apiUrl}/${RESOURCE.auth}/register`,
      registerDto
    );
  }

  emailExists(email: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrl}/${RESOURCE.user}/email/exists`,
      { email }
    );
  }

  deleteAvatar(): Observable<User> {
    return this.http
      .delete<User>(`${environment.apiUrl}/${RESOURCE.user}/avatar`)
      .pipe(tap((user) => this.setUser(user)));
  }

  acceptInvitation(key: string, email: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrl}/${RESOURCE.company}/accept-invitation`,
      { email, key }
    );
  }

  acceptInvitationAndResetPassword(
    data: AcceptInivationAndResetPasswordDto
  ): Observable<LoginResponseDto> {
    return this.http.post<LoginResponseDto>(
      `${environment.apiUrl}/${RESOURCE.auth}/invite/accept`,
      data
    );
  }
}
