import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private _menuOpenBS$ = new BehaviorSubject<boolean>(true);
  menuOpen$ = this._menuOpenBS$.asObservable();

  get isMenuOpen() {
    return this._menuOpenBS$.value;
  }
  set isMenuOpen(open: boolean) {
    this._menuOpenBS$.next(open);
  }
}
