import { Injectable } from '@angular/core';
import { ResourceBaseService } from './resource-base.service';
import { Listing } from '@shared/models';
import { Observable, take, tap } from 'rxjs';
import { environment } from '@env';
import { RESOURCE } from '@core';

@Injectable({
  providedIn: 'root',
})
export class BiddingService extends ResourceBaseService<Listing> {
  get(): Observable<Listing[]> {
    return this.http
      .get<Listing[]>(`${environment.apiUrl}/property/biddings`)
      .pipe(tap((items) => this.resources.set(items)));
  }

  startBidding(propertyId: number): Observable<void> {
    return this.http
      .post<void>(
        `${environment.apiUrl}/${RESOURCE.bidding}/${propertyId}/start-bidding`,
        {}
      )
      .pipe(
        tap(() => {
          this.get().pipe(take(1)).subscribe();
        })
      );
  }

  stopBidding(propertyId: number): Observable<void> {
    return this.http
      .post<void>(
        `${environment.apiUrl}/${RESOURCE.bidding}/${propertyId}/stop-bidding`,
        {}
      )
      .pipe(
        tap(() => {
          this.get().pipe(take(1)).subscribe();
        })
      );
  }
}
