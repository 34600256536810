import {
  Component,
  EventEmitter,
  inject,
  input,
  OnInit,
  Output,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService, URLS } from '@core';
import {
  ButtonModule,
  IconComponent,
  MenuModule,
  SkeletonLoaderComponent,
} from '@shared/ui';
import {
  CompanyService,
  PrivilegeService,
  UserService,
} from '@shared/services';
import { ICompany, User } from '@shared/models';
import { Load } from '@shared/utils';
import { environment } from '@env';
import { filter, mergeMap } from 'rxjs';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    IconComponent,
    RouterModule,
    MenuModule,
    ButtonModule,
    TranslateModule,
    RouterModule,
    SkeletonLoaderComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  showMenu = input(false);
  @Output() toggleMenu = new EventEmitter<void>();
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UserService);
  private readonly companyService = inject(CompanyService);
  private readonly privilegeService = inject(PrivilegeService);
  private readonly router = inject(Router);
  readonly mainUrl = environment.mainUrl;
  loadUser = new Load<User>();
  loadCompanies = new Load<ICompany[]>();

  get isCompanyUser() {
    return this.companyService.isCompanyUser;
  }

  get selectedCompany() {
    return this.companyService.selectedCompany();
  }

  ngOnInit(): void {
    this.loadUser.perform(this.userService.getOne(), () => {
      this.authService.token = '';
      this.userService.setUser(undefined);
      this.router.navigate([URLS.login]);
      return [];
    });
    this.loadCompanies.perform(this.companyService.getAll());

    this.companyService.selectedCompany$
      .pipe(
        filter((selectedCompany) => !!selectedCompany?.id),
        mergeMap(() => this.privilegeService.get())
      )
      .subscribe();
  }

  toggleMenuClick(): void {
    this.toggleMenu.emit();
  }

  signOut() {
    this.authService.token = '';
    this.userService.setUser(undefined);
    setTimeout(() => {
      window.location.href = environment.mainUrl;
    }, 100);
  }
}
