import { Injectable } from '@angular/core';
import { RESOURCE } from '@core';
import { environment } from '@env';
import { CompanySettings } from '@shared/models';
import { ResourceBaseService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingsService extends ResourceBaseService<CompanySettings> {
  getCompanySettings() {
    return this.http.get<CompanySettings>(
      `${environment.apiUrl}/${RESOURCE.companySettings}`
    );
  }

  updateCompanySettings(companySettings: Partial<CompanySettings>) {
    return this.http.put<CompanySettings>(
      `${environment.apiUrl}/${RESOURCE.companySettings}`,
      companySettings
    );
  }

  createCompanySettings(companySettings: Partial<CompanySettings>) {
    return this.http.post<CompanySettings>(
      `${environment.apiUrl}/${RESOURCE.companySettings}`,
      companySettings
    );
  }
}
